import React, { useCallback, useRef } from 'react';
import AppearText from '../appear-text';
import { OverlayElementProps } from '../../module/overlay/overlay-controller';
import { AppearController } from '../../controllers/appear-controller';

export interface InitLoadingOverlayProps extends OverlayElementProps {
  onLoadingEnd: () => void;
}

const InitLoadingOverlay: React.FC<InitLoadingOverlayProps> = ({
  isOpen,
  exit,
  onLoadingEnd,
}) => {
  const backgroundRef = useRef<HTMLDivElement>();
  const contentRef = useRef<HTMLDivElement>();

  const onTextAppearEnd = useCallback(() => {
    const backgroundElement = backgroundRef.current;
    const contentElement = contentRef.current;

    if (!backgroundElement) return;

    backgroundElement.style.animation = 'transparent 2s linear 1';
    backgroundElement.style.opacity = '0';

    setTimeout(() => {
      contentElement.style.animation = 'transparent 0.3s linear 1';
      contentElement.style.opacity = '0';
      contentElement.onanimationend = onLoadingEnd;
    }, 2000);
  }, []);

  return (
    <>
      <div
        ref={backgroundRef}
        id={'overlay-background'}
        style={{
          background: 'black',
          opacity: 1,
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 10,
        }}
      />
      <div
        id={'overlay-content'}
        ref={contentRef}
        style={{
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 10,
          background: 'transparent',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', gap: '16px' }}>
          <AppearController
            delay={2000}
            duration={1000}
          >
            <p
              style={{
                color: 'white',
                position: 'relative',
                fontSize: 48,
                fontWeight: 'bold',
              }}
            >
              JUNO
            </p>
          </AppearController>
          <AppearController
            delay={3000}
            duration={1000}
            onAppear={onTextAppearEnd}
          >
            <p
              style={{
                color: 'white',
                position: 'relative',
                fontSize: 48,
                fontWeight: 'bold',
              }}
            >
              ARCHIVE
            </p>
          </AppearController>
        </div>
      </div>
    </>
  );
};

export default InitLoadingOverlay;
