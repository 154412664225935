import React, { useCallback, useMemo, useState } from 'react';
import CameraController from '../controllers/camera-controller';
import ModelSampler from '../components/model-sampler';
import useSpaceBackground from '../hooks/use-space-background';
import CordinateLines from '../components/coordinate-lines';
import ModelSampler2, { Model } from '../components/model-sampler2';
import CustomText from '../components/custom-text';

const human: Model = {
  path: '/assets/human.glb',
  spread: 60,
  position: {
    x: 10,
    y: 10,
    z: 10,
  },
  scale: [30, 30, 30],
  pointSize: 0.01,
};

const avocado: Model = {
  path: '/assets/avocado.glb',
  spread: 30,
  position: {
    x: 10,
    y: 10,
    z: 10,
  },
  scale: [100, 100, 100],
  pointSize: 0.01,
};

const camera: Model = {
  path: '/assets/camera.glb',
  spread: 1,
  position: {
    x: 10,
    y: 10,
    z: 10,
  },
  scale: [2, 2, 2],
  pointSize: 0.01,
};

const fox: Model = {
  path: '/assets/fox.glb',
  spread: 1,
  position: {
    x: 10,
    y: 10,
    z: 10,
  },
  scale: [0.05, 0.05, 0.05],
  pointSize: 0.01,
};

const duck: Model = {
  path: '/assets/duck.glb',
  spread: 1,
  position: {
    x: 10,
    y: 10,
    z: 10,
  },
  scale: [0.05, 0.05, 0.05],
  pointSize: 0.01,
};

const waterbottle: Model = {
  path: '/assets/waterbottle.glb',
  spread: 1,
  position: {
    x: 10,
    y: 10,
    z: 10,
  },
  scale: [50, 50, 50],
  pointSize: 0.01,
};

const shoe: Model = {
  path: '/assets/shoe.glb',
  spread: 1,
  position: {
    x: 10,
    y: 10,
    z: 10,
  },
  scale: [10, 10, 10],
  pointSize: 0.01,
};

const lightBulb: Model = {
  path: '/assets/lightbulb.glb',
  spread: 100,
  position: {
    x: 10,
    y: 10,
    z: 10,
  },
  scale: [100, 100, 100],
  pointSize: 0.01,
};

const Home = () => {
  const [model, setModel] = useState(human);
  useSpaceBackground();

  return (
    <>
      <CameraController>
        <ModelSampler2 {...model} />
      </CameraController>
      <CustomText
        bold
        position={[-4, -6, 10]}
        onPointerDown={() => {
          setModel(camera);
        }}
      >
        {'camera'}
      </CustomText>
      <CustomText
        bold
        position={[-4, -4, 10]}
        onPointerDown={() => {
          setModel(human);
        }}
      >
        {'human'}
      </CustomText>
      <CustomText
        bold
        position={[1, -6, 10]}
        onPointerDown={() => {
          setModel(lightBulb);
        }}
      >
        {'lightBulb'}
      </CustomText>
      <CustomText
        bold
        position={[1, -4, 10]}
        onPointerDown={() => {
          setModel(avocado);
        }}
      >
        {'avocado'}
      </CustomText>
      <CustomText
        bold
        position={[5, -4, 10]}
        onPointerDown={() => {
          setModel(fox);
        }}
      >
        {'fox'}
      </CustomText>
      <CustomText
        bold
        position={[8, -4, 10]}
        onPointerDown={() => {
          setModel(duck);
        }}
      >
        {'duck'}
      </CustomText>
      <CustomText
        bold
        position={[7, -6, 10]}
        onPointerDown={() => {
          setModel(waterbottle);
        }}
      >
        {'waterbottle'}
      </CustomText>
      <CustomText
        bold
        position={[12, -6, 10]}
        onPointerDown={() => {
          setModel(shoe);
        }}
      >
        {'shoe'}
      </CustomText>
      <CustomText
        bold
        position={[0, -8, 10]}
      >
        {'Use arrows to rotate the model'}
      </CustomText>
    </>
  );
};

export default Home;
