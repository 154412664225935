import React, { PropsWithChildren, MutableRefObject, useEffect, useRef } from 'react';

export const AppearController: React.FC<
  PropsWithChildren & { delay: number; duration: number; onAppear?: () => void }
> = ({ children, delay, duration, onAppear }) => {
  const appearRef = useRef<HTMLDivElement>();

  useEffect(() => {
    setTimeout(() => {
      const element = appearRef.current;

      console.log(element);

      if (element) {
        element.style.transition = `all ${duration / 1000}s linear`;
        element.style.opacity = '1';
        element.style.transform = 'translateY(0px)';
      }

      if (!onAppear) return;

      setTimeout(() => {
        onAppear?.();
      }, duration);
    }, delay);
  }, []);

  return <Appear appearRef={appearRef}>{children}</Appear>;
};

const Appear: React.FC<
  PropsWithChildren & {
    appearRef: MutableRefObject<HTMLDivElement>;
  }
> = ({ children, appearRef }) => {
  return (
    <div
      style={{
        position: 'relative',
        transform: 'translateY(8px)',
        opacity: 0,
        willChange: 'transform',
      }}
      ref={appearRef}
    >
      {children}
    </div>
  );
};
