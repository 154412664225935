import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { OverlayContext } from './overlay-provider';
import OverlayController, { OverlayElementType } from './overlay-controller';

let elementId = 1;

function useOverlay({ exitOnUnmount = true } = {}) {
  const context = useContext(OverlayContext);

  if (context == null) {
    throw new Error('useOverlay is only available within OverlayProvider.');
  }

  const { mount, unmount } = context;
  const [id] = useState(() => String(elementId++));

  const overlayRef = useRef<{ close: () => void }>(null);

  useEffect(() => {
    return () => {
      if (exitOnUnmount) {
        unmount?.(id);
      }
    };
  }, [exitOnUnmount, id, unmount]);

  return useMemo(
    function () {
      return {
        open: function (overlayElement: OverlayElementType) {
          return mount?.(
            id,
            <OverlayController
              ref={overlayRef}
              overlayElement={overlayElement}
              onExit={() => {
                unmount?.(id);
              }}
            />,
          );
        },
        close: function () {
          overlayRef?.current?.close();
        },
        exit: function () {
          unmount?.(id);
        },
      };
    },
    [id, mount, unmount],
  );
}

export default useOverlay;
