import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

//Pages
import Home from './pages/index';
import CanvasLayout from './components/canvas-layout';
import OverlayProvider from './module/overlay/overlay-provider';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <OverlayProvider>
        <CanvasLayout>
          <Home />
        </CanvasLayout>
      </OverlayProvider>
    ),
  },
]);
const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
