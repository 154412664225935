import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

export interface OverlayElementProps {
  isOpen: boolean;
  close: () => void;
  exit: () => void;
}

export type OverlayElementType = React.FC<OverlayElementProps>;

const OverlayController = forwardRef(function OverlayContrller(
  {
    overlayElement: OverlayElement,
    onExit,
  }: {
    overlayElement: OverlayElementType;
    onExit: () => void;
  },
  ref
) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => setIsOpen(false), []);

  useImperativeHandle(
    ref,
    () => {
      return { close: handleClose };
    },
    [handleClose]
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      setIsOpen(true);
    });
  }, []);

  return <OverlayElement isOpen={isOpen} close={handleClose} exit={onExit} />;
});

export default OverlayController;
