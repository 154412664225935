import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

interface OverlayContextProps {
  mount?: (id: string, element: JSX.Element | JSX.Element[]) => void;
  unmount?: (id: string) => void;
}

export const OverlayContext = createContext<OverlayContextProps>({
  mount: undefined,
  unmount: undefined,
});

function OverlayProvider({ children }: PropsWithChildren) {
  const [overlayById, setOverlayById] = useState([]);

  const mount = useCallback(
    (id: string, element: JSX.Element | JSX.Element[]) => {
      setOverlayById((current) => {
        const cloned = [...current];
        cloned.push({
          id,
          element,
        });
        return cloned;
      });
    },
    []
  );

  const unmount = useCallback((id: string) => {
    setOverlayById((current) => {
      const cloned = [...current];
      const deleteIndex = cloned.findIndex(
        (overlayById) => overlayById.id === id
      );
      cloned.splice(deleteIndex, 1);

      return cloned;
    });
  }, []);

  useEffect(() => {
    if (overlayById.length !== 0) {
      document.body.style.overflow = "hidden";
      return;
    }
    document.body.style.overflow = "auto";
  }, [overlayById]);

  const context = useMemo(() => ({ mount, unmount }), [mount, unmount]);

  return (
    <>
      <OverlayContext.Provider value={context}>
        {overlayById.map(({ id, element }) => (
          <React.Fragment key={id}>{element}</React.Fragment>
        ))}
        {children}
      </OverlayContext.Provider>
    </>
  );
}

export default OverlayProvider;
