import { useEffect, useState } from 'react';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';

const useSpaceBackground = () => {
  const { scene } = useThree();
  const [init, setInit] = useState(false);

  useEffect(() => {
    const vertices = [];

    for (let i = 0; i < 10000; i++) {
      const x = THREE.MathUtils.randFloatSpread(1500);
      const y = THREE.MathUtils.randFloatSpread(1500);
      const z = THREE.MathUtils.randFloatSpread(1500);

      vertices.push(x, y, z);
    }

    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    const material = new THREE.PointsMaterial({ color: 0x888888 });
    const points = new THREE.Points(geometry, material);
    scene.add(points);

    setInit(true);

    return () => {
      scene.remove(points);
      setInit(false);
    };
  }, [scene]);

  return { init };
};

export default useSpaceBackground;
