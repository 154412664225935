import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as THREE from 'three';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Float32BufferAttribute, Group, Object3DEventMap, Points, Scene } from 'three';
import { getShaderById } from '../utils/getShaderById';
import { useFrame } from '@react-three/fiber';
import app from '../App';

export interface Model {
  path: string;
  position?: {
    x: number;
    y: number;
    z: number;
  };
  rotation?: {
    x: number;
    y: number;
    z: number;
  };
  scale: [number, number, number];
  pointSize?: number;
  spread?: number;
}
const ModelSampler2 = ({ ...model }: Model) => {
  const currentSceneRef = useRef<Scene | Group<Object3DEventMap>>();
  const currentScaleRef = useRef<[number, number, number]>([1, 1, 1]);
  const pointsRef = useRef<Points>(null);
  const flagRef = useRef(false);
  const switchRef = useRef(true);
  const progressRef = useRef(0);

  const pixelRatio = Math.min(window.devicePixelRatio, 100); //apply device render ratio threshold
  const vertexShader = getShaderById('vertexShader');
  const fragmentShader = getShaderById('fragmentShader');

  const getScene = useCallback(async (path: string) => {
    const loader = new GLTFLoader();

    return await new Promise<THREE.Scene | Group<Object3DEventMap>>((res, rej) => {
      loader.load(
        path,
        (gltf) => {
          res(gltf.scene);
        },
        (event) => {},
        (err) => {
          rej(err);
        },
      );
    });
  }, []);

  const shaderMaterial = new THREE.ShaderMaterial({
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
    uniforms: {
      uResolution: new THREE.Uniform(
        new THREE.Vector2(
          window.innerWidth * pixelRatio,
          window.innerHeight * pixelRatio,
        ),
      ),
      uProgress: new THREE.Uniform(progressRef.current),
      uColorA: new THREE.Uniform(new THREE.Color('#00000')),
      uColorB: new THREE.Uniform(new THREE.Color('#00000')),
    },
    blending: 2,
    depthWrite: false,
  });

  /** gets the position array from the given model scene */
  const getScenePositionVector = useCallback(
    (scene: THREE.Scene | Group<Object3DEventMap>) => {
      const position = [];

      scene.traverse((mesh) => {
        //@ts-ignore
        if (!mesh?.geometry || !mesh?.isMesh || !mesh?.geometry?.attributes?.position)
          return;

        // @ts-ignore
        const meshGeometry = mesh.geometry;

        meshGeometry.attributes.position.array.forEach((coordinate: number) => {
          position.push(coordinate);
        });
      });

      return position;
    },
    [],
  );

  const normalizePosition = useCallback((position1: number[], position2: number[]) => {
    if (position1.length === position2.length) return [position1, position2];

    let small = position1.length < position2.length ? position1 : position2;
    let large = position1.length > position2.length ? position1 : position2;
    const newArray = [...small];

    for (let i = 0; i < large.length / 3; i++) {
      const startIndex = i * 3;

      if (i > small.length / 3) {
        newArray[startIndex] = small[0];
        newArray[startIndex + 1] = small[1];
        newArray[startIndex + 2] = small[2];
      }
    }

    if (position1.length < position2.length) return [newArray, large];

    return [large, newArray];
  }, []);

  const applyScale = useCallback(
    (buffer: Float32BufferAttribute, scale: [number, number, number]) => {
      const xScale = scale[0];
      const yScale = scale[1];
      const zScale = scale[2];

      for (let i = 0; i < buffer.count; i++) {
        let x = buffer.getX(i);
        let y = buffer.getY(i);
        let z = buffer.getZ(i);

        x *= xScale;
        y *= yScale;
        z *= zScale;

        buffer.setXYZ(i, x, y, z);
      }

      return buffer;
    },
    [],
  );

  //init
  useEffect(() => {
    (async () => {
      const { path, scale } = model;
      console.log(path);

      const newScene = await getScene(path);
      const newVector = getScenePositionVector(newScene);
      const initVector = Array(newVector.length).fill(0);

      const [a, b] = normalizePosition(initVector, newVector);

      const initBufferAttribute = new THREE.Float32BufferAttribute(a, 3);
      const newBufferAttribute = new THREE.Float32BufferAttribute(b, 3);

      const newGeometry = new THREE.BufferGeometry();
      newGeometry.setAttribute('position', initBufferAttribute);
      newGeometry.setAttribute('aPositionTarget', applyScale(newBufferAttribute, scale));
      newGeometry.getAttribute('position').needsUpdate = true;

      pointsRef.current.geometry = newGeometry;
      currentSceneRef.current = newScene;
      currentScaleRef.current = scale;
      flagRef.current = true;
    })();

    return () => {};
  }, []);

  useEffect(() => {
    (async () => {
      if (!currentSceneRef.current) return;
      const { path, scale } = model;

      console.time('load model');
      const newScene = await getScene(path);
      console.timeEnd('load model');
      const newVector = getScenePositionVector(newScene);

      const currentScene = currentSceneRef.current;
      const currentVector = getScenePositionVector(currentScene);

      console.log('target', path);

      const [current, target] = normalizePosition(currentVector, newVector);

      const currentBuffer = applyScale(
        new THREE.Float32BufferAttribute(current, 3),
        currentScaleRef.current,
      );
      const targetBuffer = applyScale(new THREE.Float32BufferAttribute(target, 3), scale);

      const geometry = pointsRef.current.geometry;

      if (switchRef.current) {
        geometry.setAttribute('position', currentBuffer);
        geometry.setAttribute('aPositionTarget', targetBuffer);
      } else {
        geometry.setAttribute('position', targetBuffer);
        geometry.setAttribute('aPositionTarget', currentBuffer);
      }

      geometry.getAttribute('aPositionTarget').needsUpdate = true;
      geometry.getAttribute('position').needsUpdate = true;

      currentSceneRef.current = newScene;
      currentScaleRef.current = scale;
      flagRef.current = true;
      //trigger animation
    })();
  }, [model.path]);

  useFrame(() => {
    if (!flagRef.current) return;

    console.log(switchRef.current);
    if (switchRef.current) {
      //increase
      progressRef.current += 0.01;

      //stop
      if (progressRef.current >= 1) {
        progressRef.current = 1;
        flagRef.current = false;
        switchRef.current = false;
      }

      shaderMaterial.uniforms.uProgress.value = progressRef.current;
    } else {
      //decrease
      progressRef.current -= 0.01;

      //stop
      if (progressRef.current <= 0) {
        progressRef.current = 0;
        flagRef.current = false;
        switchRef.current = true;
      }

      shaderMaterial.uniforms.uProgress.value = progressRef.current;
    }
  });

  return (
    <>
      <points
        ref={pointsRef}
        material={shaderMaterial}
      />
    </>
  );
};

export default ModelSampler2;
