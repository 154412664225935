import React, { useRef } from 'react';
import {
  KeyboardControls,
  OrbitControls,
  PerspectiveCamera,
  useKeyboardControls,
} from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

const CameraController = ({ children }) => {
  return (
    <KeyboardControls
      map={[
        { keys: ['ArrowUp'], name: 'moveForward' },
        { keys: ['w', 'ㅈ'], name: 'rotateForward' },
        { keys: ['ArrowDown'], name: 'moveBackward' },
        { keys: ['s', 'ㄴ'], name: 'rotateBackward' },
        { keys: ['ArrowLeft'], name: 'moveLeft' },
        { keys: ['a', 'ㅁ'], name: 'rotateLeft' },
        { keys: ['ArrowRight'], name: 'moveRight' },
        { keys: ['d', 'ㅇ'], name: 'rotateRight' },
        { keys: ['Space'], name: 'jump' },
      ]}
    >
      <OrbitControls />
      <CameraPerspectiveController>{children}</CameraPerspectiveController>
    </KeyboardControls>
  );
};

export default CameraController;

const CameraPerspectiveController = ({ children }) => {
  const cameraRef = useRef<THREE.PerspectiveCamera>();
  const [subscribeKeys, getKeys] = useKeyboardControls();

  useFrame(() => {
    //no camera
    const camera = cameraRef.current;
    if (!camera) return;

    //no input
    const { rotateForward, rotateBackward, rotateLeft, rotateRight } = getKeys();
    if (!rotateForward && !rotateBackward && !rotateLeft && !rotateRight) return;

    //handle inputs
    let rotateX = camera.rotation.x;
    let rotateY = camera.rotation.y;

    if (rotateForward) {
      rotateX = rotateX + 0.025;
    }

    if (rotateBackward) {
      rotateX = rotateX - 0.025;
    }

    if (rotateLeft) {
      rotateY = rotateY + 0.025;
    }

    if (rotateRight) {
      rotateY = rotateY - 0.025;
    }

    camera.rotation.x = rotateX;
    camera.rotation.y = rotateY;
  });

  return (
    <PerspectiveCamera
      ref={cameraRef}
      rotation={[0, 0, 0]}
    >
      {children}
    </PerspectiveCamera>
  );
};
