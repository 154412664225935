import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import useOverlay from '../module/overlay/use-overlay';
import InitLoadingOverlay from './overlay/init-loading-overlay';
import { gsap } from 'gsap';

const CanvasLayout = ({ children }) => {
  const { open, exit } = useOverlay();
  const [init, setInit] = useState(false);
  const [animationEnd, setAnimationEnd] = useState(false);

  const handleCreated = useCallback(() => {
    setTimeout(() => {
      setInit(true);
    }, 2000);
  }, []);

  const handleLoadingEnd = useCallback(() => {
    setAnimationEnd(true);
    setTimeout(() => {
      exit();
    }, 1000);
  }, []);

  useEffect(() => {
    open(({ ...props }) => {
      return (
        <InitLoadingOverlay
          {...props}
          onLoadingEnd={handleLoadingEnd}
        />
      );
    });
  }, []);

  return (
    <>
      <Canvas
        style={{
          width: '100vw',
          height: '100vh',
          background: 'black',
        }}
        camera={{
          position: [0, 0, 2000],
        }}
        onCreated={handleCreated}
      >
        {init && children}
        {animationEnd && <Zoom />}
      </Canvas>
    </>
  );
};

export default CanvasLayout;

const Zoom = () => {
  const { camera } = useThree();

  useEffect(() => {
    gsap.to(camera.position, {
      z: 50,
      duration: 2,
      ease: 'power4',
    });
  }, []);

  return <></>;
};
